<template>
  <div class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mt-5">
          <!-- <h1>Harshita Jain Design Studio</h1> -->
          <h1>About Us</h1>

          <p>Our Founder and Head Designer, Harshita Jain is an alumni of Pearl Academy, Delhi, and Central Saint
            Martins, London. </p>

          <p>After winning the prestigious DLF Emporio Design Awards 2017, she launched her Design Studio in Guwahati,
            Assam, in 2019.</p>
          <p>
            HARSHITA JAIN is a luxury design label that caters to the many avatars of a 21st century woman: Elegance for
            evenings, breeziness for brunches, comfort for corporates, and grace for the festivities of a grand indian
            wedding.
          </p>
          <p>With a team of in-house designers, hand-embroiders, tailors, and local artisans, every outfit is created
            with a personal touch.</p>
          <p>At Harshita Jain, we aim to maximise comfort and utility, with our love for surface embellishments making
            each ensemble unique.</p>
        </div>
      </div>
    </div>
  </div>
</template>



<style scoped>
.about {
  min-height: 80vh;
}
</style>

<script>
export default {
  mounted() {
    this.$gtag.pageview(this.$route);
  }
}
</script>